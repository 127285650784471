import monaco from 'monaco-js';

const VueTemplateSuggestions = (range) => [
    {
        label: 'ODataGrid',
        kind: monaco.languages.CompletionItemKind.Function,
        detail: 'Vue Component',
        documentation: '<o-data-grid>\n\t\n</o-data-grid>',
        insertText: '<o-data-grid :data-object="${1:dataObjectId}">\n\t${2}\n</o-data-grid>',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range
    },
    {
        label: 'ODataGrid:basic',
        kind: monaco.languages.CompletionItemKind.Snippet,
        detail: 'Vue Component Snippet',
        documentation: 'ODataGrid Snippet',
        insertText: '<o-data-grid :data-object="${1:dataObjectId}">\n\t<o-column field="${2:name}" width="200"/>\n</o-data-grid>',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range
    },
    {
        label: 'OColumn',
        kind: monaco.languages.CompletionItemKind.Function,
        detail: 'Vue Component',
        documentation: 'OColumn configuration component used together with ODataGrid and OLookup',
        insertText: '<o-column field="${1:name}" :width="${2:200}"></o-column>',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range
    },
    {
        label: 'OColumn:lookup',
        kind: monaco.languages.CompletionItemKind.Snippet,
        detail: 'Vue Component Snippet',
        documentation: 'OColumn with lookup editor',
        insertText: '<o-column field="${1:name}" :width="${2:200}" v-slot:editor="{modelValue: row}">'
            + '\n\t<o-data-lookup'
            + '\n\t\t:data-object="${3:lookupDataObject}"'
            + '\n\t\tv-model="row.${1}">'
            + '\n\t\t<o-column field="${4:lookupField}" :width="${5:200}"></o-column>'
            + '\n\t</o-data-lookup>'
            + '\n</o-column>',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range
    },
    {
        label: 'ODataLookup',
        kind: monaco.languages.CompletionItemKind.Function,
        detail: 'Vue Component',
        documentation: 'ODataLookup',
        insertText: '<o-data-lookup :data-object="${1:lookupDataObject}" v-model="${2:currentItem}">'
            + '\n\t<o-column field="${3:lookupField}" :width="${4:200}"></o-column>'
            + '\n</o-data-lookup>',
        insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
        range: range
    },
];






const RazorSuggsestions = (range) => [

];
const HtmlSuggsestions = (range) => [

];

export default class MonacoCompletionItemsProvider {
    static isRegistered = false;

    constructor() {
        if (!MonacoCompletionItemsProvider.isRegistered) {
            // this.registerVueCompletionItemProvider();
            this.registerRazorCompletionItemProvider();
            this.registerHtmlCompletionItemProvider();
            MonacoCompletionItemsProvider.isRegistered = false;

            
        }
    }

    registerVueCompletionItemProvider() {
        monaco.languages.registerCompletionItemProvider('vue', {
            provideCompletionItems: (model, position) => {
                let textUntilPosition = model.getValueInRange({
                    startLineNumber: 1,
                    startColumn: 1,
                    endLineNumber: position.lineNumber,
                    endColumn: position.column
                });

                const openTags = textUntilPosition.match(/<template/g) ?? [];
                const closedTags = textUntilPosition.match(/<\/template/g) ?? [];

                let match = openTags.length > 0 && (openTags.length !== closedTags.length);

                if (!match) {
                    return { suggestions: [] };
                }
                const word = model.getWordUntilPosition(position);
                const range = {
                    startLineNumber: position.lineNumber,
                    endLineNumber: position.lineNumber,
                    startColumn: word.startColumn,
                    endColumn: word.endColumn
                };

                return {
                    suggestions: [
                        ...VueTemplateSuggestions(range),
                        ...HtmlSuggsestions(range),
                    ],
                };

            }
        });
    }
    registerRazorCompletionItemProvider() {
        monaco.languages.registerCompletionItemProvider('razor', {
            provideCompletionItems: (model, position) => {
                // let textUntilPosition = model.getValueInRange({
                //     startLineNumber: 1,
                //     startColumn: 1,
                //     endLineNumber: position.lineNumber,
                //     endColumn: position.column
                // });

                // const match = true;

                // if (!match) {
                //     return { suggestions: [] };
                // }
                const word = model.getWordUntilPosition(position);
                const range = {
                    startLineNumber: position.lineNumber,
                    endLineNumber: position.lineNumber,
                    startColumn: word.startColumn,
                    endColumn: word.endColumn
                };

                return {
                    suggestions: [
                        ...RazorSuggsestions(range),
                        ...HtmlSuggsestions(range),
                        ...VueTemplateSuggestions(range),
                    ],
                };

            }
        });
    }

    registerHtmlCompletionItemProvider() {
        monaco.languages.registerCompletionItemProvider('html', {
            resolveCompletionItem: (item, ct) => {
                if (item.label === 'debugger:o365' && window.o365 && window.o365.userSession?.personId) {
                    return {
                        ...item,
                        insertText: item.insertText.replace('PERSON_ID', window.o365.userSession.personId)
                    }
                } else {
                    return item;
                }
            },
            provideCompletionItems: (model, position) => {
                let textUntilPosition = model.getValueInRange({
                    startLineNumber: 1,
                    startColumn: 1,
                    endLineNumber: position.lineNumber,
                    endColumn: position.column
                });

                const openScriptTags = textUntilPosition.match(/<script/g) ?? [];
                const closedScriptTags = textUntilPosition.match(/<\/script/g) ?? [];
                const openTemplateTags = textUntilPosition.match(/<template/g) ?? [];
                const closedTemplateTags = textUntilPosition.match(/<\/template/g) ?? [];

                const matchScript = (openScriptTags.length > 0 && openScriptTags.length === closedScriptTags.length);
                const matchTemplate = (openScriptTags.length > 0 && openTemplateTags.length === closedTemplateTags.length);

                if (!matchScript && !matchTemplate) {
                    return { suggestions: [] };
                }
                const word = model.getWordUntilPosition(position);
                const range = {
                    startLineNumber: position.lineNumber,
                    endLineNumber: position.lineNumber,
                    startColumn: word.startColumn,
                    endColumn: word.endColumn
                };

                if (matchScript) {
                    return { suggestions: [...HtmlSuggsestions(range), ...VueTemplateSuggestions(range)] };
                } else {
                    return {
                        suggestions: [
                            ...HtmlSuggsestions(range),
                            ...VueTemplateSuggestions(range)
                        ],
                    };
                }
            }
        });
    }
}
